import {
	createStyles,
	Container,
	Title,
	Text,
	Button,
	Box,
	Group,
} from '@mantine/core';
import { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import React from 'react';

const NAMESPACE = 'pages/not-found';

const useStyles = createStyles((theme) => ({
	container: {
		paddingBlock: '5vh',
		minHeight: '100vh',
		display: 'grid',
		placeItems: 'center',
	},

	image: {
		opacity: 0.25,
		fontFamily: theme.headings.fontFamily,
		lineHeight: '25vh',
		textAlign: 'center',
		fontSize: '30vh',
		fontWeight: 900,
		pointerEvents: 'none',
		userSelect: 'none',
		display: 'grid',
		placeItems: 'center',

		[theme.fn.smallerThan('sm')]: {
			fontSize: '8rem',
		},
	},

	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},

	title: {
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 38,

		[theme.fn.smallerThan('sm')]: {
			fontSize: 32,
		},
	},

	description: {
		maxWidth: 540,
		margin: 'auto',
		marginTop: theme.spacing.xl,
		marginBottom: theme.spacing.xl * 1.5,
		textAlign: 'center',
		fontSize: 'lg',
	},
}));

const Page: NextPage = () => {
	const { classes } = useStyles();
	const { t } = useTranslation(NAMESPACE);

	return (
		<Container className={classes.container}>
			<Box className={classes.content}>
				<Text className={classes.image} color='dimmed'>
					404
				</Text>
				<Title className={classes.title}>{t('title')}</Title>
				<Text color='dimmed' className={classes.description}>
					{t('description')}
				</Text>
				<Group position='center'>
					<Link href='/' passHref>
						<Button component='a' size='md'>
							{t('button')}
						</Button>
					</Link>
				</Group>
			</Box>
		</Container>
	);
};

export default Page;
